import PageHeader from '@asaprint/asap/components/PageHeader.js';
import PagePaper from '@asaprint/asap/components/PagePaper.js';
import authorized from '@asaprint/asap/decorators/authorized.js';
import { RouteHandle } from '@asaprint/asap/interfaces.js';
import {
  DASHBOARD_ROUTE,
  EXPORTS_PRODUCT_SPECIFICATIONS_ROUTE,
  EXPORTS_PRODUCTION_ROUTE,
  EXPORTS_TIME_ENTRIES_ROUTE,
} from '@asaprint/asap/routes.js';
import { Permission } from '@asaprint/common/access.js';
import FontAwesomeSvgIcon from '@engined/client/components/FontAwesomeSvgIcon.js';
import { useLocale } from '@engined/client/contexts/LocaleContext.js';
import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import { DATE_FORMAT } from '@engined/client/locales.js';
import { LoaderFunctionArgs, MetaFunctionArgs } from '@engined/core/interfaces.js';
import { faFileExcel, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { endOfDay, format, startOfDay, subDays } from 'date-fns';
import React, { useState } from 'react';

interface OwnProps {}

type Props = OwnProps;

const Exports: React.FunctionComponent<Props> = () => {
  return (
    <>
      <PageHeader
        title={
          <>
            <FontAwesomeIcon icon={faSave} /> Export
          </>
        }
      />

      <ExportForm action={EXPORTS_PRODUCTION_ROUTE} title="Dáta produkcie" />
      <ExportForm
        action={EXPORTS_PRODUCT_SPECIFICATIONS_ROUTE}
        title="Produkty a ich špecifikácie"
        description="Vyberte dátumy odkedy dokedy sa majú exportovať dáta (berie sa dátum vytvorenia produktu v databáze)"
      />
      <ExportForm action={EXPORTS_TIME_ENTRIES_ROUTE} title="Pracovný log" />
    </>
  );
};

export default authorized(Permission.ExportsRead)(Exports);

export const loader = async ({ params, request, context: { req, apollo } }: LoaderFunctionArgs<'id' | 'productId'>) => {
  return null;
};

export const handle: RouteHandle = {
  breadcrumbs: [
    { text: 'Dashboard', to: DASHBOARD_ROUTE },
    {
      text: 'Administrácia',
    },
    {
      text: 'Export',
    },
  ],
  meta: ({ meta }: MetaFunctionArgs) => {
    return {
      title: `Export | Administrácia | ${meta.title}`,
    };
  },
};

const ExportForm: React.FC<{ action: string; title: string; description?: string }> = ({
  action,
  title,
  description,
}) => {
  const { t } = useLocale();
  const [startAt, setStartAt] = useState(startOfDay(subDays(new Date(), 30)));
  const [endAt, setEndAt] = useState(endOfDay(new Date()));

  const onStartAtChangeCallback = useEventCallback((d: Date) => {
    setStartAt(d);
  });

  const onEndAtChangeCallback = useEventCallback((d: Date) => {
    setEndAt(d);
  });

  return (
    <PagePaper title={title} sx={{ mb: 4 }}>
      <Box
        component="form"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        action={action}
        method="get"
      >
        <Typography variant="body1">{description ?? 'Vyberte odkedy dokedy chcete exportovať dáta'}:</Typography>
        <input type="hidden" name="from" value={format(startAt, 'yyyy-MM-dd')} />
        <input type="hidden" name="to" value={format(endAt, 'yyyy-MM-dd')} />
        <Box display="flex" alignItems="center" gap={2}>
          <DatePicker value={startAt} onChange={onStartAtChangeCallback} format={t(DATE_FORMAT)} maxDate={endAt} />
          <DatePicker value={endAt} onChange={onEndAtChangeCallback} format={t(DATE_FORMAT)} minDate={startAt} />
          <Button type="submit" color="primary" startIcon={<FontAwesomeSvgIcon icon={faFileExcel} />}>
            Vygenerovať
          </Button>
        </Box>
      </Box>
    </PagePaper>
  );
};

ExportForm.displayName = 'ExportForm';
